import { withErrorPage } from '@grandvisionhq/next'
import { compose } from '@grandvisionhq/utils'
import { Page, Props } from '@grandvisionhq/www-next/pages/container'

import { withCms, withLayout } from '../../cms'
import { defaultErrorProps } from '../../components/error-props'

export default compose<Props, Props>(
  withCms(),
  withErrorPage(defaultErrorProps),
  withLayout()
)(Page)
